import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private _authSvc: AuthService,
		protected _router: Router,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.indexOf('i18n') > -1) {
			return next.handle(request);
		}

		if (this._authSvc.user) {
			request = this.addToken(request, this._authSvc.token);
		}

		return next.handle(request).pipe(
			map(event => {
				if (event instanceof HttpResponse) {
					if (event.body && event.body.constructor.name === 'Object' && !Object.keys(event.body).length) {
						event = event.clone({
							body: null
						});
					}
					return event;
				}
			}),
			catchError((error: any, caught: Observable<HttpEvent<any>>) => {
				const loginUrl = environment.origin + 'auth/login';
				if (error instanceof HttpErrorResponse && error.status === 401 && request.url !== loginUrl && this._authSvc.user) {
					localStorage.removeItem('token');
					localStorage.removeItem('user');
					this._router.navigate(['/auth/login']);
				}
				return throwError(error);
			})
		);
	}

	private async logout() {
		await this._authSvc.logout();
	}

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}
}
