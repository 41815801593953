import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {

	constructor(
		private _router: Router,
		private _authSvc: AuthService
	) { }


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (state.url === '/maintenance' && !this._authSvc.user?.company?.archived_at) {
			this._router.navigateByUrl('/');
			return false;
		} else if (state.url !== '/maintenance' && this._authSvc.user?.company?.archived_at) {
			this._router.navigateByUrl('/maintenance');
			return false;
		}
		return true;
	}

}
