import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LaravelService } from '../../common/services/laravel.service';
import { User } from '../interfaces/user.interface';
import { environment } from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UsersService extends LaravelService<User> {

	prefix: string = 'users';

	/**
	 * Comprueba si puedes asignar una nueva order a un compañero mediante su id
	 *
	 * @param {number} partnerId
	 * @return {*}  {Promise<User>}
	 * @memberof UsersService
	 */
	async checkPartnerId(partnerId: number): Promise<User> {
		return firstValueFrom(this._http.get(environment.origin + this.prefix + '/check-partner-id/' + partnerId)) as Promise<User>;
	}


	/**
	 * Actualiza la fecha de proteccion de datos
	 *
	 * @memberof AuthService
	 */
	async lopd() {
		return firstValueFrom(this._http.post(environment.origin + this.prefix + '/lopd', {}));
	}
}
