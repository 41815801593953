import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController, IonicSafeString, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthService } from './auth/services/auth.service';
import { UsersService } from './home/services/users.service';
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	public appPages = [
		{ title: 'HOME', url: '/home', icon: 'home', function: '' },
	];
	constructor(
		private _platform: Platform,
		private _translateSvc: TranslateService,
		private _toastCtrl: ToastController,
		private _alertCtrl: AlertController,
		private _usersSvc: UsersService,
		public auth: AuthService
	) {
		this._platform.ready().then(() => {
			this.configLanguage();
			this.initializeApp();
		});
	}

	/**
	 * Config language
	 *
	 * @param lang: any
	 */
	configLanguage() {
		// Inicializamos el idioma
		if (!localStorage.getItem('language')) {
			localStorage.setItem('language', 'es');
		}
		this._translateSvc.setDefaultLang(localStorage.getItem('language'));
	}

	/**
	 * Setup language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		localStorage.setItem('language', lang);
		this._translateSvc.use(lang);
	}

	async initializeApp() {
		try {
			if (Capacitor.getPlatform() !== 'web') {
				// Scree lock orientation
				window.screen.orientation.lock('portrait');
			}

			if (!this.auth.user.lopd) {
				// Retardamos 100 ms para que de tiempo a cargar las traducciones
				setTimeout(async () => {
					const alert = await this._alertCtrl.create({
						header: this._translateSvc.instant('LOGIN.LABELS.LOPD_HEADER'),
						message: new IonicSafeString(this._translateSvc.instant('LOGIN.LABELS.LOPD_MESSAGE', { url: environment.front })),
						backdropDismiss: false,
						buttons: [
							{
								text: this._translateSvc.instant('GENERIC.BUTTONS.CANCEL'),
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => this.auth.logout()
							}, {
								text: this._translateSvc.instant('GENERIC.BUTTONS.ACCEPT'),
								handler: () => this._usersSvc.lopd()
							}
						]
					});
					await alert.present();
				}, 100);

			}
		} catch (error) {
			if (error.name && error.name === 'TimeoutError') {
				const toast = await this._toastCtrl.create({
					message: 'Error en la sincronización. Sincronize de forma manual.',
					duration: 5048
				});
				await toast.present();
			}
		}
	}
}
