import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../home/interfaces/user.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	prefix = 'auth/';

	tokenRefreshed: boolean = true;
	userSubject$ = new BehaviorSubject(null);
	tokenRefreshedSubject = new Subject<boolean>();

	get user(): User {
		return JSON.parse(localStorage.getItem('user'));
	}
	set user(v: User) {
		if (v) {
			localStorage.setItem('user', JSON.stringify(v));
		} else {
			localStorage.removeItem('user');
		}
		this.userSubject$.next(v);
	}

	get token(): string {
		return localStorage.getItem('token');
	}
	set token(v: string) {
		if (v) {
			localStorage.setItem('token', v);
		} else {
			localStorage.removeItem('token');
		}
	}

	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _menuCtrl: MenuController
	) { }

	/**
	 * Loguea al usuario y se establecen los valores de usuario y token locales
	 *
	 * @param {*} [params={}]
	 * @return {*}  {Promise<any>}
	 * @memberof AuthService
	 */
	async login(params: any = {}): Promise<any> {
		const result: any = await firstValueFrom(this._http.post(environment.origin + this.prefix + 'login-app', params));
		this.user = result?.user;
		this.token = result?.token;
	}

	/**
	 * Obtiene de nuevo el usuario y el token de sesión
	 *
	 * @return {*}  {Promise<any>}
	 * @memberof AuthService
	 */
	async refresh(): Promise<User> {
		try {
			const result: any = await firstValueFrom(this._http.get(environment.origin + this.prefix + 'me', {}));
			this.user = result?.data;
			this.token = result?.data?.token;
		} catch (error) {
			this.user = null;
			this.token = null;
		}

		return this.user;
	}

	/**
	 * Desloguea al usuario y redirige al login
	 *
	 * @memberof AuthService
	 */
	async logout() {
		await firstValueFrom(this._http.delete(environment.origin + this.prefix + 'logout'));
		this.user = this.token = null;
		this._menuCtrl.enable(false);
		this._router.navigateByUrl('/auth/login');
	}

	/**
	 * Envia un email al usuario recordando la contraseña
	 *
	 * @param {string} email
	 * @return {*}  {Promise<any>}
	 * @memberof AuthService
	 */
	async forgot(email: string): Promise<any> {
		await firstValueFrom(this._http.get(environment.origin + this.prefix + 'forgot?mail=' + email));
	}

	/**
	 * Devuelve el estado de la compañia
	 *
	 * @return {*}  {Promise<boolean>}
	 */
	async companyMaintenance(): Promise<boolean> {
		return firstValueFrom(await this._http.get(environment.origin + 'companies/maintenance')) as Promise<boolean>;
	}
}
