import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WebcamModule } from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/services/auth.service';
import { TokenInterceptor } from './auth/services/token.interceptor';
import { HttpClientInterceptor } from './interceptors/httpClient.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			backButtonText: '' // Set an empty string to have no text next to the back icon
		}),
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		WebcamModule
	],
	providers: [
		AuthService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeUser,
			deps: [AuthService],
			multi: true
		},
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpClientInterceptor,
			multi: true
		},
		Camera,
		Chooser,
		File,
		MediaCapture,
		Geolocation

	],
	bootstrap: [AppComponent],
})
export class AppModule { }

function initializeUser(authSvc: AuthService) {
	return () => {
		if (authSvc.token) {
			return authSvc.refresh();
		} else {
			return Promise.resolve(null);
		}
	};
}
