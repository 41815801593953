import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

	constructor(
		private _alertSvc: AlertController,
		private _translateSvc: TranslateService,
		private _router: Router
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => event),
			timeout(300000),
			catchError(
				(httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
					if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
						this._router.navigateByUrl('/auth/login');
					}
					return throwError(() => httpErrorResponse);
				}
			)
		);
	}

	async showAlert(message: string) {
		const alert = await this._alertSvc.create({
			header: this._translateSvc.instant('GENERIC.LABELS.ERROR'),
			message
		});
		await alert.present();
	}

}
