import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})

export class AuthGuard implements CanActivate {

	constructor(
		private _router: Router,
		private _auth: AuthService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(async (resolve, reject) => {
			try {
				const user = await this._auth.user;

				if (user) {
					if (state.url === '/auth/login') {
						this._router.navigateByUrl('/');
						throw new Error('authorized');
					}
				} else {
					if (state.url !== '/auth/login') {
						this._router.navigateByUrl('/auth/login');
						throw new Error('unauthorized');
					}
				}
				resolve(true);
			} catch (error) {
				resolve(false);
			}
		});
	}

}
