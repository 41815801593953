import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pagination } from '../interfaces/pagination';

@Injectable({
	providedIn: 'root'
})
export abstract class LaravelService<T> {

	abstract prefix: string;
	primaryKey: string = 'id';

	constructor(
		protected _http: HttpClient
	) { }

	get(body: any = {}): Observable<Array<T>> {
		return this._http.post(environment.origin + this.prefix, body) as Observable<Array<T>>;
	}

	paginate(body: any = {}): Observable<Pagination<T>> {
		return this._http.post(environment.origin + this.prefix, body) as Observable<Pagination<T>>;
	}

	find(id: number | string): Observable<T> {
		return this._http.get(environment.origin + this.prefix + '/' + id) as Observable<T>;
	}

	add(body: T, option = {}): Promise<{ data: any, message: string; }> {
		return firstValueFrom(this._http.put(environment.origin + this.prefix, body, option)) as Promise<any>;
	}

	update(id: number | string, body: any, option = {}): Promise<{ data: any, message: string; }> {
		return firstValueFrom(this._http.patch(environment.origin + this.prefix + `/${id}`, body, option)) as Promise<any>;
	}

	saveOrUpdate(params: T, id: any | string = null): Promise<{ data: any, message: string; }> {
		if (!id && this.primaryKey && params[this.primaryKey]) {
			id = params[this.primaryKey];
		}
		return id ? this.update(id, params) : this.add(params);
	}

	delete(id: number | string): Promise<number> | Promise<number> {
		return firstValueFrom(this._http.delete(environment.origin + this.prefix + '/' + id)) as Promise<number>;
	}

}
